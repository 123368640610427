import { Field, Input } from 'components';
import { ValidationRule } from 'questionFlow/types';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FullResidentialHistoryType } from './Accordion';
import { addressInputs } from './ResidentialHistoryForm';

interface Props {
  name: addressInputs;
  errors: { [key in keyof typeof addressInputs]: string };
  autoComplete?: string;
  values: FullResidentialHistoryType;
  setValues: React.Dispatch<React.SetStateAction<FullResidentialHistoryType>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  validationRules: { [key: string]: ValidationRule[] };
}

const cmsLocation = '/questions/residence/residentialHistory';

const useContent = (t: TFunction) => ({
  [addressInputs.flat]: {
    label: t(`${cmsLocation}.flat.label`),
    placeholder: t(`${cmsLocation}.flat.placeholder`),
  },
  [addressInputs.houseName]: {
    label: t(`${cmsLocation}.houseName.label`),
    placeholder: t(`${cmsLocation}.houseName.placeholder`),
  },
  [addressInputs.streetNumber]: {
    label: t(`${cmsLocation}.streetNumber.label`),
    placeholder: t(`${cmsLocation}.streetNumber.placeholder`),
  },
  [addressInputs.street]: {
    label: t(`${cmsLocation}.street.label`),
    placeholder: t(`${cmsLocation}.street.placeholder`),
  },
  [addressInputs.city]: {
    label: t(`${cmsLocation}.city.label`),
    placeholder: t(`${cmsLocation}.city.placeholder`),
  },
  [addressInputs.postalCode]: {
    label: t(`${cmsLocation}.postalCode.label`),
    placeholder: t(`${cmsLocation}.postalCode.placeholder`),
  },
  [addressInputs.country]: {
    label: t(`${cmsLocation}.country.label`),
    placeholder: t(`${cmsLocation}.country.placeholder`),
  },
});

const AddressFormField = ({
  name,
  errors,
  autoComplete,
  values,
  validationRules,
  setErrors,
  setValues,
  setIsDirty,
}: Props) => {
  const { t } = useTranslation();
  const content = useContent(t);

  const validate = (v: string, rules: ValidationRule[]) =>
    rules.find(({ rule }) => !rule(v, values.address));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (errors[name as addressInputs]) {
      const result = validate(value, validationRules[name]);
      setErrors({ ...errors, [name]: result ? t(result.error as any) : '' });
    }
    setValues({
      ...values,
      address: {
        ...values.address,
        [name]: value,
      },
    });
    setIsDirty(true);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    let errorsUpdate = { ...errors };
    const result = validate(value, validationRules[name]);

    //These two fields are only required if the other is not present
    //Required error should be removed from both once one is added
    if (
      value &&
      name === addressInputs.flat &&
      errors[addressInputs.streetNumber] &&
      !validate(values.address.streetNumber, validationRules[addressInputs.streetNumber])
    ) {
      errorsUpdate[addressInputs.streetNumber] = '';
    } else if (
      value &&
      name === addressInputs.streetNumber &&
      errors[addressInputs.flat] &&
      !validate(values.address.flat || '', validationRules[addressInputs.flat])
    ) {
      errorsUpdate[addressInputs.flat] = '';
    }

    setErrors({ ...errorsUpdate, [name]: result ? t(result.error as any) : '' });
  };

  return (
    <Field
      className="mb-4 md:w-full"
      data-testid={name}
      label={content[name].label}
      validationError={errors[name]}
    >
      <Input
        large
        id={`address-${name}`}
        name={name}
        defaultValue={values.address[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        aria-invalid={!!errors[name]}
        placeholder={content[name].placeholder}
        autoComplete={autoComplete}
      />
    </Field>
  );
};

export default AddressFormField;
