import { ChevronDownIcon, ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect, useMemo, useState } from 'react';
import AddressForm from './ResidentialHistoryForm';
import { ValidationRule } from 'questionFlow/types';
import { TFunction, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton } from 'components';
import { ResidentialHistoryType, AddressType } from 'generated/graphql';
import { useTranslatedValues } from 'hooks/useTranslatedValues';

export interface FullResidentialHistoryType extends ResidentialHistoryType {
  address: AddressType;
}
interface InnerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  residentialHistoryItem: FullResidentialHistoryType;
  setResidentialHistoryItem: (item: ResidentialHistoryType) => void;
  deleteResidentialHistoryItem: () => void;
  criteriaMet?: boolean;
  currentSet?: boolean;
  isUKAddress?: boolean;
  validationRules: { [key: string]: ValidationRule[] };
}

export const blankResidentialHistory = {
  uuid: null,
  residenceType: null,
  address: {
    street: '',
    streetNumber: '',
    flat: '',
    houseName: '',
    city: '',
    country: '',
    postalCode: '',
    formattedAddress: '',
    placeId: null,
  },
  start: '',
  end: '',
  isCurrent: false,
};

const translations = {
  addAddress: `/questions/residence/residentialHistory.addAddress`,
  current: `/questions/residence/residentialHistory.current`,
} as const;

const formatDates = (startDate: string, endDate: string | null, language: string, t: TFunction) => {
  const formattedStartDate = new Date(startDate).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
  });
  const formattedEndDate = endDate
    ? new Date(endDate).toLocaleDateString(language, { year: 'numeric', month: 'short' })
    : t('/questions/residence/residentialHistory.current');
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const AccordionInner = ({
  isOpen,
  setIsOpen,
  residentialHistoryItem,
  deleteResidentialHistoryItem,
  setResidentialHistoryItem,
  isUKAddress,
  currentSet,
  criteriaMet,
  ...props
}: InnerProps) => {
  const { t, i18n } = useTranslation();
  const content = useTranslatedValues(translations);

  const handleSetResidentialHistoryItem = (item: ResidentialHistoryType) => {
    setResidentialHistoryItem(item);
    setIsOpen(false);
  };

  const isNewAddress = useMemo(() => !residentialHistoryItem?.address?.formattedAddress, [
    residentialHistoryItem,
  ]);

  const titleText = useMemo(
    () =>
      !isNewAddress && residentialHistoryItem?.address?.formattedAddress
        ? residentialHistoryItem.address.formattedAddress.toUpperCase()
        : content.addAddress,
    [residentialHistoryItem?.address?.formattedAddress, content, isNewAddress],
  );

  const dateText = useMemo(() => {
    if (residentialHistoryItem.start) {
      if (residentialHistoryItem.isCurrent) {
        return formatDates(residentialHistoryItem.start, null, i18n.language, t);
      }
      return formatDates(
        residentialHistoryItem.start,
        residentialHistoryItem.end,
        i18n.language,
        t,
      );
    }
    return '';
  }, [
    residentialHistoryItem.start,
    residentialHistoryItem.end,
    residentialHistoryItem.isCurrent,
    i18n.language,
    t,
  ]);

  return (
    <div
      data-testid="residential-accordion"
      className="grid grid-cols-6 md:grid-none md:flex md:flex-row w-full mb-6 items-center"
    >
      <div
        className={clsx(
          !isOpen ? 'col-span-5' : 'col-span-6',
          'border border-grey-3 rounded-md w-full md:max-w-xl',
          isNewAddress && !isOpen && 'border-dashed',
        )}
      >
        <div
          className="flex flex-row p-6 justify-between border-b"
          role="button"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={() => setIsOpen(true)}
        >
          <div className="truncate">
            <p className={clsx('w-full truncate', isNewAddress && 'text-blue-3')} title={titleText}>
              {titleText}
            </p>
            <p className="font-semibold capitalize">{dateText}</p>
          </div>
          {isOpen ? (
            <ChevronUpIcon className="w-6 text-blue-3 flex-none" />
          ) : isNewAddress ? (
            <PlusIcon className={clsx('w-6 flex-none text-blue-3')} />
          ) : (
            <ChevronDownIcon className="w-6 text-blue-3 flex-none" />
          )}
        </div>
        {isOpen && (
          <AddressForm
            residentialHistoryItem={
              isUKAddress
                ? {
                    ...residentialHistoryItem,
                    address: { ...residentialHistoryItem.address, country: 'GB' },
                  }
                : residentialHistoryItem
            }
            isUKAddress={isUKAddress}
            currentSet={currentSet}
            isNewAddress={isNewAddress}
            setResidentialHistoryItem={handleSetResidentialHistoryItem}
            {...props}
          />
        )}
      </div>
      {!isOpen && !isNewAddress && (
        <IconButton onClick={deleteResidentialHistoryItem} className="place-self-center md:ml-7">
          <TrashIcon className="w-6" />
        </IconButton>
      )}
    </div>
  );
};

interface Props {
  residentialHistory: ResidentialHistoryType[];
  setResidentialHistoryItem: (residentialHistoryItem: ResidentialHistoryType) => void;
  setOpenState?: (openState: boolean) => void;
  deleteResidentialHistoryItem: (uuid: string) => void;
  validationRules: { [key: string]: ValidationRule[] };
  single?: boolean;
  isUKAddress?: boolean;
  criteriaMet?: boolean;
}

const Accordion = ({
  residentialHistory,
  setResidentialHistoryItem,
  deleteResidentialHistoryItem,
  single,
  criteriaMet,
  isUKAddress,
  setOpenState,
  ...props
}: Props) => {
  const [open, setOpen] = useState<string | null>(single ? null : '');

  const handleToggleOpen = (key: string | null) => {
    if (key === open) {
      setOpen('');
      setOpenState?.(false);
    } else {
      setOpen(key);
      setOpenState?.(true);
    }
  };

  const [currentSet, setCurrentSet] = useState<boolean>(false);

  useEffect(() => {
    setCurrentSet(
      residentialHistory.filter((item: ResidentialHistoryType) => item.isCurrent).length === 1,
    );
  }, [residentialHistory]);

  const handleUpdateItem = (residentialHistoryItem: ResidentialHistoryType) => {
    if (residentialHistoryItem.uuid) {
      handleToggleOpen(residentialHistoryItem.uuid);
    }
    setResidentialHistoryItem(residentialHistoryItem);
  };

  const residentialHistoryItemsToRender = useMemo<ResidentialHistoryType[]>(() => {
    if (single) {
      return residentialHistory.length === 0 ? [blankResidentialHistory] : residentialHistory;
    }
    return [...residentialHistory, blankResidentialHistory];
  }, [residentialHistory, single]);

  return (
    <>
      {residentialHistoryItemsToRender.map((residentialHistoryItem, ix) => (
        <AccordionInner
          key={`${residentialHistoryItem.uuid} - ${ix}`}
          isOpen={residentialHistoryItem.uuid === open}
          setIsOpen={() => handleToggleOpen(residentialHistoryItem.uuid)}
          currentSet={currentSet}
          criteriaMet={criteriaMet}
          residentialHistoryItem={residentialHistoryItem as FullResidentialHistoryType}
          setResidentialHistoryItem={handleUpdateItem}
          deleteResidentialHistoryItem={() =>
            deleteResidentialHistoryItem(residentialHistoryItem.uuid)
          }
          isUKAddress={isUKAddress}
          {...props}
        />
      ))}
    </>
  );
};

export default Accordion;
