import { useGetAllIncomeUuiDsQuery } from 'generated/graphql';
import { useTranslatedValues } from 'hooks/useTranslatedValues';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import goToTruelayerAuthUrl from './goToTruelayerAuthUrl';
import * as Sentry from '@sentry/react';
import Error from 'pages/Error';
import { InternalRouteLayout, OutlineNumberedBullet, Button, Spinner } from 'components';
import { Trans } from 'react-i18next';
import { ShieldCheckIcon } from '@heroicons/react/solid';
import TrueLayer from 'assets/images/TrueLayer.png';
import FCA from 'assets/images/FCA.png';

const cmsLocation = '/questions/income/openBanking';

const translations = {
  title: `${cmsLocation}.title`,
  subtitle: `${cmsLocation}.subtitle`,
  description: `${cmsLocation}.description`,
  openBankingButton: `${cmsLocation}.Button`,
  uploadDocumentsDescription: `${cmsLocation}.uploadDocumentsDescription`,
  uploadDocumentsDescriptionLink: `${cmsLocation}.uploadDocumentsDescriptionLink`,
  altTextTrueLayerLogo: `${cmsLocation}.altTextTrueLayerLogo`,
  altTextFCALogo: `${cmsLocation}.altTextFCALogo`,
  underBullets: `${cmsLocation}.underBullets`,
  bullet1: `${cmsLocation}.bullet1`,
  bullet2: `${cmsLocation}.bullet2`,
};

const relatedObjectUuidUrlKey = 'related_object_uuid';
const uuidRegex = new RegExp(/\w{8}-(\w{4}-){3}\w{12}/, 'i');
const searchValidationRules = {
  uuid: (uuid: string | null) => !uuid || uuidRegex.test(uuid),
};
const safelyGetQueryParams = (search: string) => {
  let params = new URLSearchParams(search);
  const uuid = params.get(relatedObjectUuidUrlKey);
  return searchValidationRules.uuid(uuid) ? (uuid as string) : '';
};

const OpenBankingPage = () => {
  const content = useTranslatedValues(translations as any);
  const history = useHistory();
  const [relatedObjectUuid] = useState(safelyGetQueryParams(history.location.search));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { data, loading: loadingData, error: errorData } = useGetAllIncomeUuiDsQuery();
  const { broadcastEvent } = useAnalyticsEvent();

  const onClickHandler = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    broadcastEvent('Clicked OB Button');

    try {
      await goToTruelayerAuthUrl({ uuid: relatedObjectUuid });
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (data) {
      const isRealIncomeUuid = data?.me?.income?.incomeSources?.find(
        incomeSource => incomeSource.uuid === relatedObjectUuid,
      );
      if (isRealIncomeUuid) {
        setLoading(false);
      } else {
        Sentry.captureException('Income UUID not found');
        setError(true);
      }
    }
  }, [data, relatedObjectUuid]);

  if (error || errorData) {
    return <Error />;
  }

  if (loading || loadingData) {
    return (
      <div data-testid="loading" className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <InternalRouteLayout.Body>
      <div className="mt-10 grid grid-cols-1 md:grid-cols-12">
        <div className="col-span-8">
          <h1 className="font-semibold text-3xl mb-4">{content.title}</h1>
          <p className="mb-6">
            <Trans
              i18nKey={`${cmsLocation}.subtitle`}
              components={{
                b: <b />,
              }}
            />
          </p>
        </div>
        <div className="col-span-full md:col-span-8 flex flex-col space-y-6 lg:space-y-8 mb-8 lg:mb-12 mt-4">
          <OutlineNumberedBullet number="1" contentKey={`${cmsLocation}.bullet1`} />
          <OutlineNumberedBullet number="2" contentKey={`${cmsLocation}.bullet2`} />

          <p className="lg:pt-4">
            <Trans
              i18nKey={`${cmsLocation}.underBullets`}
              components={{
                b: <b />,
              }}
            />
          </p>
        </div>

        <div className="md:col-span-8">
          <Button onClick={onClickHandler} className="flex items-center mt-3 mb-8">
            <ShieldCheckIcon className="h-6 w-6 mr-2" />
            {content.openBankingButton}
          </Button>

          <p className="mb-8 text-sm">
            <Trans
              i18nKey={`${cmsLocation}.description`}
              components={{
                b: <b />,
              }}
            />
          </p>
          <div className="mb-12 opacity-40">
            <img
              src={TrueLayer}
              className="mr-10 inline-block"
              alt={content.altTextTrueLayerLogo}
            />
            <img src={FCA} className="w-100 inline-block" alt={content.altTextFCALogo} />
          </div>
        </div>
      </div>
    </InternalRouteLayout.Body>
  );
};

export default OpenBankingPage;
