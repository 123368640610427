import { useEffect, useRef } from 'react';
import { ApolloProvider } from '@apollo/client';
import './i18n';
import usePolyfill from './i18n/displayNamesPolyfill';
import { getClient } from 'api/apolloClient';
import useAuthentication from 'authentication/useAuthentication';
import * as Sentry from '@sentry/browser';
import { Suspense } from 'react';
import RouteSwitcher from 'routing/RouteSwitcher';
import { useGetInitialDataQuery, GetInitialDataQuery } from 'generated/graphql';
import Server500 from 'pages/Error';
import Onboarding from 'pages/onboarding';
import { InitialDataContext } from 'api/data/initial-data/useInitialData';
import { Spinner } from 'components';
import AnalyticsProvider from 'monitoring/analytics';
import { useLocation } from 'react-router-dom';

const initGA = (data: GetInitialDataQuery) => {
  (window as any).gtag &&
    (window as any).gtag('config', 'G-C99BWZK9P0', { user_id: `${data?.me?.uuid}` });
};

const initIntercom = (data: GetInitialDataQuery) => {
  // we add the user details to the intercom session
  (window as any).Intercom &&
    (window as any).Intercom('boot', {
      app_id: 'ytl3ufpf',
      name: `${data?.me?.name?.firstName} ${data?.me?.name?.lastName}`,
      email: `${data?.me?.email}`,
      user_id: `${data?.me?.uuid}`,
      user_hash: `${data?.me?.intercomToken}`,
    });
};

const initSentry = (data: GetInitialDataQuery) => {
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setUser({ email: `${data?.me?.email}` });
  });
};

const FullscreenLoading = () => (
  <div className="h-screen w-screen flex items-center justify-center">
    <Spinner />
  </div>
);

function AppContent() {
  const authenticationState = useAuthentication();
  const location = useLocation();
  const { error, data, loading } = useGetInitialDataQuery();
  usePolyfill();

  const initialDataFetchedOnce = useRef(false);

  useEffect(() => {
    if (data && !initialDataFetchedOnce.current) {
      initialDataFetchedOnce.current = true;
      initGA(data);
      initSentry(data);
      initIntercom(data);
    }
  }, [data]);

  if (loading || authenticationState === 'pending') {
    return <FullscreenLoading />;
  }

  // The flow is confusing, however if logged out state is set then it can only be from a token refresh fail
  // If auth state is anything else then it was a legitimate error with the query
  if (error && !data && authenticationState !== 'logged-out') {
    return <Server500 />;
  }

  return (
    <InitialDataContext.Provider value={data}>
      <AnalyticsProvider pathname={location.pathname} data={data}>
        <>
          <RouteSwitcher />
          {data && data.me.latestValidation && <Onboarding />}
        </>
      </AnalyticsProvider>
    </InitialDataContext.Provider>
  );
}
function App() {
  const client = getClient();

  return (
    <Suspense fallback={<FullscreenLoading />}>
      <ApolloProvider client={client}>
        <AppContent />
      </ApolloProvider>
    </Suspense>
  );
}

export default App;
