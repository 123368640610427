import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback, useMemo } from 'react';
import { TFunction, useTranslation, Trans } from 'react-i18next';
import {
  incomeConditionTypesEnum,
  DefaultIncomeQuestionComponentProps,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { OutlineNumberedBullet, Button, TextButton } from 'components';
import { ShieldCheckIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import TrueLayer from 'assets/images/TrueLayer.png';
import FCA from 'assets/images/FCA.png';
import goToTruelayerAuthUrl from 'pages/OpenBanking/goToTruelayerAuthUrl';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';
import { WizardConditionType } from 'generated/graphql';

const cmsLocation = '/questions/income/openBanking';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  description: t(`${cmsLocation}.description`),
  openBankingButton: t(`${cmsLocation}.Button`),
  uploadDocumentsDescription: t(`${cmsLocation}.uploadDocumentsDescription`),
  uploadDocumentsDescriptionLink: t(`${cmsLocation}.uploadDocumentsDescriptionLink`),
  altTextTrueLayerLogo: t(`${cmsLocation}.altTextTrueLayerLogo`),
  altTextFCALogo: t(`${cmsLocation}.altTextFCALogo`),
  underBullets: t(`${cmsLocation}.underBullets`),
  bullet1: t(`${cmsLocation}.bullet1`),
  bullet2: t(`${cmsLocation}.bullet2`),
});

const stepName = StepNamesDefaultIncome.openBanking;

export const shouldRenderManualUpload = (conditions?: WizardConditionType[]) => {
  const manualUploadCondition = conditions?.find(
    cond => cond.name === incomeConditionTypesEnum.ADD_MANUAL_DOC_UPLOAD,
  );
  // @ts-ignore
  return manualUploadCondition ? manualUploadCondition.boolValue : true;
};

const OpenBanking = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const { broadcastEvent } = useAnalyticsEvent();

  const handleSubmit = useCallback(() => {
    broadcastEvent('Clicked Manual Upload Button');
    onSubmit({ key: stepName, value: null });
  }, [onSubmit, broadcastEvent]);

  const subtitle = (
    <Trans
      i18nKey={`${cmsLocation}.subtitle`}
      components={{
        b: <b />,
      }}
    />
  );

  const onClickHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    broadcastEvent('Clicked OB Button');
    goToTruelayerAuthUrl({ uuid: initialValues.uuid || '' });
  };

  const renderManualUpload = useMemo(() => shouldRenderManualUpload(data.conditions), [data]);

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={subtitle}
      isFooterHidden
      {...props}
    >
      <div className="grid grid-cols-1 md:grid-cols-12">
        <div className="col-span-full md:col-span-8 flex flex-col space-y-6 lg:space-y-8 mb-8 lg:mb-12 mt-4">
          <OutlineNumberedBullet number="1" contentKey={`${cmsLocation}.bullet1`} />
          <OutlineNumberedBullet number="2" contentKey={`${cmsLocation}.bullet2`} />

          <p className="lg:pt-4">
            <Trans
              i18nKey={`${cmsLocation}.underBullets`}
              components={{
                b: <b />,
              }}
            />
          </p>
        </div>

        <div className="w-full md:col-span-8">
          <Button
            onClick={onClickHandler}
            className="flex items-center justify-center mt-3 mb-8 w-full lg:w-auto"
          >
            <ShieldCheckIcon className="h-6 w-6 mr-2" />
            {content.openBankingButton}
          </Button>

          <p className="mb-8 text-sm">
            <Trans
              i18nKey={`${cmsLocation}.description`}
              components={{
                b: <b />,
              }}
            />
          </p>
          <div className="mb-12 opacity-40">
            <img
              src={TrueLayer}
              className="mr-10 inline-block"
              alt={content.altTextTrueLayerLogo}
            />
            <img src={FCA} className="w-100 inline-block" alt={content.altTextFCALogo} />
          </div>

          {renderManualUpload ? (
            <p className="flex border-t-2 pt-8" data-testid="manual-upload">
              <ExclamationCircleIcon className="w-5 h-5 mr-5 flex-shrink-0" />
              <span className="text-sm">
                <div className="mb-6">
                  <Trans
                    i18nKey={`${cmsLocation}.uploadDocumentsDescription`}
                    components={{
                      b: <b />,
                    }}
                  />
                </div>
                <TextButton onClick={handleSubmit}>
                  {content.uploadDocumentsDescriptionLink}
                </TextButton>
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </QuestionOrganism>
  );
};

export default OpenBanking;
