import React, { useState, useMemo } from 'react';
import { AddressAutoComplete, Field, Button, SearchableDropdown } from 'components';
import { PostcoderAddress } from 'components/molecules/AddressAutoComplete/postcoderApi';
import { TFunction, useTranslation } from 'react-i18next';
import AddressFormField from './AddressFormField';
import AllCountryCodes from 'questionFlow/data/all_country_codes.json';
import { ValidationRule } from 'questionFlow/types';
import ResidentialFormFields from './ResidentialFormFields';
import { blankResidentialHistory, FullResidentialHistoryType } from './Accordion';
import { AddressType, ResidentialHistoryType } from 'generated/graphql';
import { addMonths, formatISO } from 'date-fns';
import { useAnalyticsErrors, useAnalyticsValues } from 'monitoring/analyticsHooks';

const cmsLocation = '/questions/residence/residentialHistory';

export enum addressInputs {
  flat = 'flat',
  streetNumber = 'streetNumber',
  street = 'street',
  city = 'city',
  postalCode = 'postalCode',
  country = 'country',
  houseName = 'houseName',
}

export enum residentialInputs {
  isCurrent = 'isCurrent',
  start = 'start',
  end = 'end',
  residenceType = 'residenceType',
}

const allInputs = {
  ...addressInputs,
  ...residentialInputs,
};

const useContent = (t: TFunction) => ({
  autoComplete: {
    label: t(`${cmsLocation}.autoComplete.label`) as string,
    placeholder: t(`${cmsLocation}.autoComplete.placeholder`) as string,
  },
  [addressInputs.country]: {
    label: t(`${cmsLocation}.country.label`),
    placeholder: t(`${cmsLocation}.country.placeholder`),
  },
  addAddress: t(`${cmsLocation}.addAddress`),
  editAddress: t(`${cmsLocation}.editAddress`),
});

interface Props {
  currentSet?: boolean;
  isUKAddress?: boolean;
  residentialHistoryItem: FullResidentialHistoryType;
  setResidentialHistoryItem: (value: ResidentialHistoryType) => void;
  validationRules: { [key: string]: ValidationRule[] };
  isNewAddress?: boolean;
}

export const blankErrors = Object.values(allInputs).reduce((a, c) => {
  return { ...a, [c]: '' };
}, {} as Record<keyof typeof allInputs, string>);

const constructSummaryLine = ({
  address: { flat, streetNumber, street, city, postalCode },
}: FullResidentialHistoryType) =>
  `${flat ? `${flat}, ` : ''}${streetNumber} ${street}, ${city}, ${postalCode}`;

const AddressForm = ({
  currentSet,
  isUKAddress,
  residentialHistoryItem,
  setResidentialHistoryItem,
  validationRules,
  isNewAddress,
}: Props) => {
  const { t, i18n } = useTranslation();
  const content = useContent(t);
  const [values, setValues] = useAnalyticsValues<FullResidentialHistoryType>(
    residentialHistoryItem as FullResidentialHistoryType,
  );
  const [errors, setErrors] = useAnalyticsErrors<Record<keyof typeof allInputs, string>>(
    blankErrors,
  );
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const regionNames = useMemo(() => new Intl.DisplayNames([i18n.language], { type: 'region' }), [
    i18n.language,
  ]);

  const countryOptions = useMemo(
    () =>
      AllCountryCodes.map(countryCode => {
        const localisedNationality = regionNames.of(countryCode);
        return { value: countryCode, label: localisedNationality };
      }).sort((a, b) => (a.label < b.label ? -1 : 1)),
    [regionNames],
  );

  const handleAddressFound = (rawAddress?: PostcoderAddress) => {
    if (!rawAddress) {
      return;
    }

    const transformedAddress = {
      ...blankResidentialHistory,
      uuid: values.uuid || null,
      address: {
        street: rawAddress.street || '',
        streetNumber: rawAddress.number || '',
        flat: rawAddress.subbuildingname || '',
        houseName: rawAddress.buildingname || '',
        city: rawAddress.posttown || '',
        country: values.address?.country as string,
        postalCode: rawAddress.postcode || '',
        formattedAddress: rawAddress.summaryline || '',
        placeId: rawAddress.uniquedeliverypointreferencenumber || '',
        houseNumber: '',
      },
    };

    setValues(transformedAddress);
    setIsDirty(false);
  };

  const validate = (v: string, rules: ValidationRule[], comparison?: any) =>
    rules.find(({ rule }) => !rule(v, comparison ? comparison : values.address));

  const handleAddAddress = () => {
    const address = values.address as AddressType;
    if (!address[addressInputs.country]) {
      const result = validate(
        address[addressInputs.country],
        validationRules[addressInputs.country],
      );
      setErrors({
        ...errors,
        [addressInputs.country]: result ? (t(result.error as any) as string) : '',
      });
    } else {
      const inputErrors = Object.values(addressInputs).reduce((errs, name) => {
        const result = validate(address[name] as string, validationRules[name]);
        return { ...errs, [name]: result ? t(result.error as any) : '' };
      }, blankErrors);

      if (!values.isCurrent) {
        const endError = validate(
          values[residentialInputs.end] as string,
          validationRules[residentialInputs.end],
          values,
        );
        inputErrors[residentialInputs.end] = endError ? t(endError.error as any) : '';
      }

      const startError = validate(
        values[residentialInputs.start] as string,
        validationRules[residentialInputs.start],
      );
      inputErrors[residentialInputs.start] = startError ? t(startError.error as any) : '';

      const residenceTypeError = validate(
        values[residentialInputs.residenceType] as string,
        validationRules[residentialInputs.residenceType],
      );
      inputErrors[residentialInputs.residenceType] = residenceTypeError
        ? t(residenceTypeError.error as any)
        : '';

      if (Object.values(inputErrors).filter(e => e !== '').length === 0) {
        setResidentialHistoryItem({
          ...values,
          end: values.isCurrent
            ? formatISO(addMonths(new Date(), 1), { representation: 'date' })
            : values.end,
          address: {
            ...address,
            formattedAddress: isDirty ? constructSummaryLine(values) : address.formattedAddress,
            placeId: address.placeId,
          },
        });
      } else {
        setErrors(inputErrors);
      }
    }
  };

  const handleSelectCountry = (newCountryCode: string) => {
    setValues({
      ...values,
      address: { ...(values.address as AddressType), country: newCountryCode },
    });
    if (errors.country) {
      setErrors({ ...errors, country: '' });
    }
  };

  return (
    <div className="p-6 w-full">
      {!isUKAddress && (
        <Field
          className="md:w-full relative z-10"
          data-testid={addressInputs.country}
          label={content[addressInputs.country].label}
          validationError={errors[addressInputs.country]}
        >
          <SearchableDropdown
            size="lg"
            placeholder={content[addressInputs.country].placeholder}
            options={countryOptions}
            onSelectValue={handleSelectCountry}
            value={values.address?.country}
            error={!!errors[addressInputs.country]}
            label={content[addressInputs.country].label}
            isNativeOnMobile
          />
        </Field>
      )}

      {values.address && values.address[addressInputs.country] && (
        <>
          <AddressAutoComplete
            id="address"
            onSelectAddress={handleAddressFound}
            label={content.autoComplete.label}
            placeholder={content.autoComplete.placeholder}
            countryCode={values.address?.country}
          />

          <div className="w-full h-0 mt-4 mb-8 border-b" />

          {[
            addressInputs.flat,
            addressInputs.houseName,
            addressInputs.streetNumber,
            addressInputs.street,
            addressInputs.city,
            addressInputs.postalCode,
          ].map(i => (
            <AddressFormField
              key={`${values.address.placeId} ${i}`}
              name={i}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              validationRules={validationRules}
              setIsDirty={setIsDirty}
            />
          ))}

          <ResidentialFormFields
            key={values.address.placeId}
            currentSet={currentSet}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            validationRules={validationRules}
          />
        </>
      )}

      <Button data-testid="add-address" type="button" onClick={handleAddAddress} className="mt-4">
        {isNewAddress ? content.addAddress : content.editAddress}
      </Button>
    </div>
  );
};

export default AddressForm;
